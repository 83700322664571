import React from "react";
import { Checkbox as StyledCheckbox } from "../../style/Forms";

const Checkbox = React.forwardRef(({ ...props }, ref) => (
  <StyledCheckbox>
    <input {...props} ref={ref} />
    <label htmlFor={props.id}>{props.label}</label>
  </StyledCheckbox>
));

export { Checkbox };
