export const measuringContactForm = dataToAnalytics => {
  if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "sent",
      category: dataToAnalytics.type,
      action: "sent",
      label: dataToAnalytics?.situation,
      eventCallback: () => {},
    });
  }
};
