import React from "react";
import { Input, Label, Validation } from "../../style/Forms";
import { Flex } from "../../style/Grid";
import { Text } from "../../style/Text";

const FirstName = ({ errors, register, showRequired, isFirst }) => (
  <Flex flexDirection="column">
    <Flex justifyContent="space-between" width={1} flexDirection={["row"]}>
      <Label htmlFor="firstName">Jméno*</Label>
      {showRequired && (
        <Text
          fontSize={0}
          textAlign={["right", "left"]}
          display={isFirst ? "block" : ["block", "none"]}
        >
          * Povinné údaje
        </Text>
      )}
    </Flex>

    <Input
      type="text"
      name="firstName"
      id="firstName"
      ref={register({ required: true })}
    />
    {errors?.firstName && (
      <Validation variant="error">Toto pole je povinné</Validation>
    )}
  </Flex>
);

export default FirstName;
