import React from "react";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { contact } from "../../const/const";
import Link from "../Link";
import { PhoneHeader } from "../../assets/Svg";

const ContactByPhone = () => (
  <Wrapper bg="panelPrimary" pt={4} pb={5}>
    <Container flexDirection="column">
      <Heading as="h2" lineHeight={1} textAlign={["left", null, "center"]} fontWeight="light" letterSpacing="caps" fontSize={[4, null, 5]} mb={3} uppercase>
        Chcete nám raději zavolat?
      </Heading>

      <Flex flexDirection={["column", null, "row"]}>
        <Flex width={["100%", null, "50%"]} pr={3} mb={[3, null, "0"]} justifyContent={["flex-start", null, "flex-end"]}>
          <Text fontSize={3} as="h3" color="bodyText" textAlign={["left", null, "right"]} fontWeight="normal" maxWidth={["400px"]} lineHeight={2}>
            Naše klientská linka vám poskytne široké spektrum užitečných informací o&nbsp;našich službách.
          </Text>
        </Flex>

        <Flex width={["100%", null, "50%"]} flexDirection="column" pl={[0, null, 3]}>
          <Text>Jsme Vám k dispozici v Po-Pá 8:00-16:00</Text>
          <Flex as={Link} href={`tel:${contact.phoneLink}`} alignItems="center">
            <Image width="34px" mr={2} src={PhoneHeader} />
            <Text fontWeight="light" letterSpacing="caps" color="cta" fontSize={[5, null, "44px"]} dangerouslySetInnerHTML={{ __html: contact.phone }} />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  </Wrapper>
);

export default ContactByPhone;
