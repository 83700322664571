import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import ContactByPhone from "../../components/ContactByPhone";
import ContactForm from "../../components/ContactForm";
import { URLS } from "../../const/const";
import SEO from "../../components/seo";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Image } from "../../style/Image";

const ContactTemplate = ({
  data: {
    wp: {
      page: {
        title,
        uri,
        featuredImage,
        seo: { metaDesc },
      },
      categories: { nodes: categories },
    },
  },
  ...props
}) => {
  const breadcrumbs = [
    {
      label: "Kontakty",
      link: URLS.contact,
    },
    { label: "Kontaktní formulář", link: uri },
  ];

  const situations = [];

  categories.map(category => {
    if (category.pages.nodes[0]) situations.push(category.pages.nodes[0]);
  });

  let chosenSituation;

  useEffect(() => {
    const queryDict = {};
    props.location.search
      .substring(1)
      .split("&")
      .forEach(item => {
        queryDict[item.split("=")[0]] = item.split("=")[1];
      });

    chosenSituation = parseInt(queryDict["type"]);
  }, []);

  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" activeNav={URLS.contact} />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mb={[3, null, 5]}>
            <Breadcrumb breadcrumbs={breadcrumbs} mt={2} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              Kontaktní formulář
            </Heading>
          </Flex>

          <Flex mb={[4, null, null, 5]} width={1} flexDirection={["column", null, null, "row"]} alignItems="flex-start">
            <Flex flexGrow={1} width={"100%"} maxWidth={["100%", null, null, "590px"]}>
              <ContactForm situations={situations} chosenSituation={chosenSituation} type="landing" />
            </Flex>

            <Flex flexGrow={1} pt={[4, null, null, 0]} pl={[0, null, null, 5]} alignSelf={["center", null, null, "flex-start"]}>
              <Image
                display="block"
                margin="0 auto"
                boxShadow="one"
                borderRadius="1"
                width={1}
                maxWidth={387}
                src={featuredImage?.sourceUrl ?? require("../../assets/images/contact/contact-form.jpg").default}
              />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <ContactByPhone />
    </Layout>
  );
};

export default ContactTemplate;

export const pageQuery = graphql`
  query PRODUCT_SELECT {
    wp {
      page(id: "kontaktni-formular", idType: URI) {
        title
        uri
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        seo {
          metaDesc
        }
      }
      categories(first: 9999, after: null) {
        nodes {
          pages {
            nodes {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`;
