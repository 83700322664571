import React from "react";
import { Box } from "../../style/Grid";

export const FlashMessage = props => (
  <Box
    bg={[
      (props.type === "success" && "negative") ||
        (props.type === "fail" && "rgba(220, 0, 0, 0.2)"),
    ]}
    width={1}
    borderRadius={1}
    px={3}
    py={20}
  >
    <Box>{props.children}</Box>
  </Box>
);
