const query = `mutation SEND_INQUIRY($input: SendInquiryInput!) {
  sendInquiry(input: $input) {
    status
    message
    sugar
  }
}
`;

export default async formData => {
  const graphqlUrl = process.env.WP_GRAPHQL_URI
    ? process.env.WP_GRAPHQL_URI
    : "/graphql";
  const result = await fetch(graphqlUrl, {
    // eslint-disable-line no-undef
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables: {
        input: {
          clientMutationId: "inq",
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
          region: formData.region,
          gdpr: formData.gdpr,
          type: formData.type,
          message: formData.message,
          product: formData.product,
          situation: formData.situation,
          kapitolClient: formData.kapitolClient,
          linkedin: formData.linkedin,
          resume: formData.resume,
          resumeName: formData.resumeName,
        },
      },
    }),
  });
  return await result.json();
};
