import React from "react";

import { Label, Select } from "../../style/Forms";
import { Flex } from "../../style/Grid";
import { Text } from "../../style/Text";

const Situation = ({ register, situations, chosenSituation }) => (
  <Flex flexDirection="column" mb={3}>
    <Flex
      justifyContent="space-between"
      width={1}
      flexDirection={["column-reverse", "row"]}
    >
      <Label htmlFor="situation">S ČÍM POTŘEBUJETE PORADIT?</Label>
      <Text fontSize={0} textAlign={["right", "left"]} mb={[3, 0]}>
        * Povinné údaje
      </Text>
    </Flex>
    <Select
      ref={register}
      name="situation"
      id="situation"
      defaultValue={chosenSituation}
    >
      {situations.map((item, index) => (
        <option key={index} value={item.slug}>
          {item.title}
        </option>
      ))}
    </Select>
  </Flex>
);

export default Situation;
