import React, { Fragment, useState } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";

import Link from "../Link";
import { Upload } from "../Forms/Upload";
import FirstName from "./FirstName";
import LastName from "./LastName";
import Situation from "./Situation";
import Email from "./Email";
import Phone from "./Phone";
import Message from "./Message";
import LinkedIn from "./LinkedIn";
import { FlashMessage } from "../FlashMessage";
import { Checkbox } from "../Forms";
import Loader from "../Loader";

import { Box, Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Form, Label, Validation } from "../../style/Forms";
import { Button } from "../../style/Button";
import { Heading } from "../../style/Heading";
import Icon from "../../style/Icon";

import { contact, URLS } from "../../const/const";
import contactFormMutation from "./ContactFormMutation";

import { measuringContactForm } from "../../utils/gtm";

const uploadValidation = value => {
  if (!value.length) {
    return;
  }
  if (value[0].size >= 5767168) {
    // eslint-disable-next-line consistent-return
    return "Maximálně 5MB";
  }
};

const ContactForm = ({ title, type, situations, chosenSituation, customMutation = null, ...styles }) => {
  const [status, setStatus] = useState("nominal");
  const [showLoader, setShowLoader] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
  });
  const watchFile = watch("resume");

  const generalContact = type === "general";
  const adviserContact = type === "adviser";
  const landingContact = type === "landing";
  const careerContact = type === "career";

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const onSubmit = async data => {
    setShowLoader(true);

    try {
      data.type = type;

      if (data.resume && data.resume[0]) {
        data.resumeName = data.resume[0].name;
        data.resume = await toBase64(data.resume[0]);
      } else {
        data.resume = "";
        data.resumeName = "";
      }

      const dataToAnalytics = {
        type: type,
        situation: data?.situation,
      };

      measuringContactForm(dataToAnalytics);

      let response = null;
      if (customMutation) {
        response = await customMutation(data);
      } else {
        response = await contactFormMutation(data);
      }

      console.log(response);

      if (response.data.sendInquiry?.sugar === "true" || response.data.sendInquiryIwp?.status === "ok" || response.data.sendInquiry?.message === "ok") {
        if (landingContact) {
          navigate("/kontaktni-formular-ok");
          return;
        }
        setStatus("success");
      } else {
        setStatus("fail");
      }
    } catch (e) {
      setStatus("fail");
      console.log("fail");
      console.error(e);
    }
  };

  return (
    <Fragment>
      {status === "success" && (
        <Flex flexDirection="column" width={1}>
          <FlashMessage type="success">
            <Flex alignItems="center">
              <Icon icon="icon-check-circle" size={26} />
              <Text fontWeight={600} ml={2}>
                Formulář byl úspěšně odeslán
              </Text>
            </Flex>
          </FlashMessage>
          <Heading
            as="h2"
            fontSize={[5, null, 7]}
            lineHeight={["32px", null, "48px"]}
            fontWeight={["light", null, "extraLight"]}
            mt={4}
            mb={4}
            uppercase
            dangerouslySetInnerHTML={{
              __html:
                generalContact || landingContact ? "Děkujeme za váš zájem o&nbsp;služby KAPITOLu" : "Děkujeme za váš zájem o&nbsp;spolupráci s&nbsp;KAPITOLem",
            }}
          />
          <Text mb={4}>
            Náš poradce Vás bude kontaktovat v&nbsp;nejkratší možné době.
            <br />
            <br />
            Přejeme hezký den.
          </Text>
        </Flex>
      )}
      {status === "fail" && (
        <Flex flexDirection="column" width={1}>
          <FlashMessage type="fail">
            <Flex alignItems="center">
              <Icon icon="icon-close" size={16} color="headlines" />
              <Text fontSize={16} lineHeight={"19px"} fontWeight={600} color={"headlines"} ml={2}>
                Formulář se nepodařilo odeslat
              </Text>
            </Flex>
          </FlashMessage>
          <Heading as="h4" my={3} uppercase>
            Bohužel, něco se pokazilo
          </Heading>
          <Text
            mb={4}
            dangerouslySetInnerHTML={{
              __html: `Můžete nas kontaktovat na telefonu ${contact.phone}, nebo to zkusit znovu.`,
            }}
          />
          <Box>
            <Button
              display="inline-block"
              variant="secondary"
              onClick={() => {
                setStatus("nominal");
                setShowLoader(false);
              }}
            >
              Zkusit znovu
            </Button>
          </Box>
        </Flex>
      )}
      {status === "nominal" && (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }} encType="multipart/form-data" {...styles}>
          {generalContact && title && (
            <Heading as="h3" mb={4} lineHeight={1} fontWeight="light" uppercase>
              {title}
            </Heading>
          )}

          {(generalContact || landingContact) && <Situation register={register} situations={situations} chosenSituation={chosenSituation} />}

          {!adviserContact && (
            <Flex mb={3} flexDirection={["column", "row"]}>
              <Box width={["100%", null, "50%"]} mr={[0, 1]} mb={[3, 0]}>
                <FirstName errors={errors} register={register} showRequired={generalContact || careerContact} />
              </Box>

              <Box width={["100%", null, "50%"]} ml={[0, 1]}>
                <LastName errors={errors} register={register} showRequired={generalContact || careerContact} />
              </Box>
            </Flex>
          )}

          {adviserContact && (
            <>
              <Box width={1} mb={3}>
                <FirstName errors={errors} register={register} showRequired={adviserContact} isFirst={adviserContact} />
              </Box>
              <Box width={1} mb={3}>
                <LastName errors={errors} register={register} />
              </Box>
            </>
          )}

          {generalContact && (
            <Flex mb={3} flexDirection={["column", "row"]}>
              <Box width={1} mr={[0, 1]} mb={[3, 0]}>
                <Email errors={errors} register={register} />
              </Box>

              <Box width={1} mx={[0, 1]} mb={[3, 0]}>
                <Phone errors={errors} register={register} />
              </Box>
            </Flex>
          )}

          {adviserContact && (
            <>
              <Box width={1} mb={3}>
                <Email errors={errors} register={register} />
              </Box>

              <Box width={1} mb={3}>
                <Phone errors={errors} register={register} />
              </Box>
            </>
          )}

          {(landingContact || careerContact) && (
            <Flex mb={3} flexDirection={["column", "row"]}>
              <Box flexDirection="column" width={["100%", null, "50%"]} mr={[0, 1]} mb={[3, 0]}>
                <Email errors={errors} register={register} />
              </Box>
              <Box flexDirection="column" width={["100%", null, "50%"]} ml={[0, 1]}>
                <Phone errors={errors} register={register} />
              </Box>
            </Flex>
          )}

          {careerContact && (
            <Fragment>
              <Flex flexDirection={["column", "row"]} mb={3}>
                <Box width={["100%", null, "50%", "100%"]} mr={[0, 1]} mb={[3, 0]}>
                  <LinkedIn register={register} />
                </Box>

               /* <Box width={["100%", null, "50%", "100%"]} maxWidth={["auto", null, null, 200]} ml={[0, 1]}>
                  <Flex flexDirection="column">
                    <Label as="span">Životopis</Label>
                    <Upload
                      type="file"
                      name="resume"
                      id="resume"
                      accept=".doc,.docx,.pdf"
                      ref={register({
                        validate: value => uploadValidation(value),
                      })}
                    />

                    {!errors?.resume && Boolean(watchFile?.length) && <Validation variant="info">{watchFile[0]?.name}</Validation>}

                    {errors.resume && <Validation variant="error">{errors.resume.message}</Validation>}
                  </Flex>
                </Box>  */
                
              </Flex>
            </Fragment>
          )}

          <Flex mb={3}>
            <Message register={register} />
          </Flex>

          <Flex mb={4}>
            <Flex flexDirection="column" width={1} mb={[3, "0"]}>
              <Checkbox
                type="checkbox"
                name="gdpr"
                id="gdpr"
                ref={register({ required: true })}
                label={
                  <Text fontSize={0} color="textGrey" lineHeight={1}>
                    Odesláním potvrzujete seznámení se&nbsp;
                    <Link fontSize={0} href={URLS.gdpr} variant="underline" fontWeight="semiBold" color="textGrey" target={"_blank"}>
                      zpracováním osobních údajů
                    </Link>{" "}
                    ze&nbsp;strany společnosti KAPITOL, a.s.
                  </Text>
                }
              />
              {errors.gdpr && <Validation variant="error">Je potřeba souhlasit</Validation>}
            </Flex>
          </Flex>

          <Flex justifyContent={adviserContact ? "center" : ["center", "flex-start"]}>
            <Button type="submit" disabled={showLoader}>
              {showLoader ? <Loader variant={"contact"} /> : "Odeslat"}
            </Button>
          </Flex>
        </Form>
      )}
    </Fragment>
  );
};

export default ContactForm;
