import React from "react";
import { Input, Label } from "../../style/Forms";
import { Flex } from "../../style/Grid";

const Message = ({ register }) => (
  <Flex flexDirection="column" width={1}>
    <Label htmlFor="firstName">Chcete nám něco vzkázat?</Label>
    <Input
      as="textarea"
      type="text"
      name="message"
      id="message"
      ref={register}
      rows={5}
    />
  </Flex>
);

export default Message;
