import React from "react";
import { Upload as StyledUpload } from "../../style/Forms";

const Upload = React.forwardRef(({ ...props }, ref) => (
  <StyledUpload>
    <label htmlFor={props.id}>Nahrát</label>
    <input {...props} ref={ref} />
  </StyledUpload>
));

export { Upload };
