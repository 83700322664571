import React from "react";
import { Input, Label } from "../../style/Forms";
import { Flex } from "../../style/Grid";

const LinkedIn = ({ register }) => (
  <Flex flexDirection="column">
    <Label htmlFor="linkedin">Linkedin profil</Label>
    <Input name="linkedin" id="linkedin" ref={register} />
  </Flex>
);

export default LinkedIn;
