import React from "react";
import { Input, Label, Validation } from "../../style/Forms";
import { Flex } from "../../style/Grid";

const Phone = ({ errors, register }) => (
  <Flex flexDirection="column">
    <Label htmlFor="phone">Telefonní číslo*</Label>
    <Input name="phone" id="phone" ref={register({ required: true })} />
    {errors?.phone && (
      <Validation variant="error">Toto pole je povinné</Validation>
    )}
  </Flex>
);

export default Phone;
