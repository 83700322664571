import React from "react";
import { Input, Label, Validation } from "../../style/Forms";
import { Flex } from "../../style/Grid";

const Email = ({ errors, register }) => (
  <Flex flexDirection="column">
    <Label htmlFor="email">Email*</Label>
    <Input name="email" id="email" ref={register({ required: true })} />
    {errors?.email && (
      <Validation variant="error">Toto pole je povinné</Validation>
    )}
  </Flex>
);

export default Email;
